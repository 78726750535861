<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="dialog = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="7" md="5" xl="4" class="py-1">
                  Tipo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_archivo"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="8" sm="5" md="4" xl="3" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados_archivo"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" sm="2" xl="1" class="py-1">
                  Id
                  <v-text-field
                    v-model.trim="filtro.id"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" xl="3" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha desde
                      <FechaPicker
                        v-model="filtro.desde"
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col>
                      Fecha hasta
                      <FechaPicker
                        v-model="filtro.hasta"
                        hide-details
                        clearable
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :headers="headers"
        :items="archivos"
        :loading="load"
        :search="search"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.estado_id == 3 && item.error != null"
            color="orange"
            class="mr-2"
            small
            @click="$swal.fire({
              icon: 'warning',
              text: item.error
            })"
          >
            fas fa-info-circle
          </v-icon>
          <BtnConfirmar
            v-if="item.estado_id == 1"
            clase="mr-2"
            icono="fas fa-check"
            color="success"
            title="Marcar como Pendiente de Procesar"
            :texto="`¿Desea marcar como <strong>Pendiente de Procesar</strong> el archivo seleccionado?`"
            :icon_button="true"
            :small="true"
            @action="cambiar_estado(item, 4)"
          />
          <BtnConfirmar
            v-if="item.estado_id == 4"
            clase="mr-2"
            icono="fas fa-undo"
            color="primary"
            title="Marcar como Subido (No procesar)"
            :texto="`¿Desea marcar como <strong>Subido</strong> el archivo seleccionado?`"
            :icon_button="true"
            :small="true"
            @action="cambiar_estado(item, 1)"
          />
          <BtnConfirmar
            v-if="item.estado_id == 1"
            icono="fas fa-trash"
            color="error"
            title="Borrar Archivo"
            :texto="`¿Desea <strong>eliminar</strong> el archivo seleccionado? (esta acción no se puede deshacer)`"
            :icon_button="true"
            :small="true"
            @action="eliminar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <ModalImportarArchivo
      v-model="dialog"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import ModalImportarArchivo from '../../components/generales/ModalImportarArchivo'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog: false,
      search: '',
      headers: [
        { text: 'Id', value: 'id', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Periodo', value: 'periodo', sortable: false, filterable: false },
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Fecha estado', value: 'estado_fecha', sortable: false, filterable: false },
        { text: 'Cantidad', value: 'detalles', align: 'end' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      archivos: [],
      filtro: {
        tipo: null,
        estado: null,
        desde: null,
        hasta: null,
        id: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('archivos/get_tipos')
    await this.$store.dispatch('archivos/get_estados')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('archivos', ['tipos_archivo', 'estados_archivo'])
  },
  components: {
    BtnFiltro,
    FechaPicker,
    BtnConfirmar,
    SearchDataTable,
    ModalImportarArchivo
  },
  methods: {
    async cambiar_estado (item, estado) {
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/cambiar_estado', {
        id: item.id,
        estado: estado
      })
        .then((res) => {
          item.estado_id = estado
          item.estado_nombre = res.nombre
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        }).catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async eliminar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/eliminar_archivo', item.id)
        .then((res) => {
          const index = this.archivos.indexOf(item)
          this.archivos.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        }).catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.tipo && !this.filtro.estado && !this.filtro.desde && !this.filtro.id) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione al menos un filtro',
          color: 'orange'
        })
      }
      this.load = true
      this.search = ''
      this.archivos = []
      await this.$store.dispatch('archivos/get_archivos', this.filtro)
        .then((res) => {
          this.archivos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    quitar (item) {
      const index = this.archivos.indexOf(item)
      this.archivos.splice(index, 1)
    },
    limpiar () {
      this.filtro = {
        tipo: null,
        estado: null,
        desde: null,
        hasta: null,
        id: null
      }
    }
  }
}
</script>